import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Grid, Button} from '@material-ui/core';
import Recommended from "../../screens/more/Recommended";

const styles = theme => ({
    root: {
        marginBottom: "0px",
        marginTop: "64px",
        maxWidth: "1300px",
        margin: "0 auto",
        // position: "absolute",
        // top: "60px",
        // left: "0",
        '@media screen and (max-width: 700px)': {
            marginBottom: "0px",
            marginTop: "50px",
        }
    },
    cardOverlay: {
        height: '400px',
        width: "70vw",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        '@media screen and (max-width: 700px)': {
            height: "400px",
            width: "100vw",
        }
    },
    card: {
        height: "400px",
        position: 'relative',
        borderRadius: "0px",
        '@media screen and (max-width: 700px)': {
            height: "400px"
        }
    },
    title: {
        color: "white",
        fontSize: "2.1rem",
        fontWeight: "100",
        fontFamily: "arial",
        textAlign: "center",
        padding: "0px 50px 0 50px",
        margin: "-30px 0px 0px 0px",
        lineHeight: "4.2rem",
        // fontFamily: 'Chronicle Display,serif',
        '@media screen and (max-width: 700px)': {
            fontSize: "40px",
            lineHeight: "40px",
            padding: "0px 20px 0 20px",
            width: "90vw"
        },
        '@media screen and (max-width: 400px)': {
            fontSize: "30px",
            lineHeight: "30px",
            padding: "0 20px 0 20px",
            width: "90vw"
        }
    },

    description: {
        color: "white",
        fontSize: "20px",
        // fontWeight: "100",
        textAlign: "center",
        maxWidth: "600px",
        margin: "0 auto",
        fontWeight: "400",
        padding: "0 50px 0 50px",
        '@media screen and (max-width: 700px)': {
            fontSize: "20px",
            lineHeight: "20px",
            padding: "0px 20px 0 20px",
            width: "70vw"
        },
        '@media screen and (max-width: 400px)': {
            fontSize: "16px",
            lineHeight: "16px",
            padding: "0 20px 0 20px",
            width: "90vw"
        }
    },
    buttons: {
        margin: "30px",
        padding: "7px 35px"
    }
})


class HomeHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            state: props.state,
            // preview: `https://images.unsplash.com/photo-1507919909716-c8262e491cde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1189&q=80`
            insider: `https://images.unsplash.com/photo-1506873589908-63bc22175dd7?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=751&q=80`,
            preview: `https://images.unsplash.com/photo-1516754982724-879e393bb556?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=889&q=80`
        };
    }

    // componentDidMount() {
    // PLACEHOLDER
    // }

    render() {
        const {classes} = this.props;
        return (
            <>
                <Grid container className={classes.root}>
                    {/* <div className={classes.card}> */}
                    <Grid item md={12} sm={12} style={{
                        // backgroundImage: `linear-gradient(rgb(223 241 255), rgba(238, 247, 255, 0)), url(${this.state.preview})`
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, .4) 0%, rgba(50, 50, 50, .8) 100%), url(${this.state.insider})`,
                        border: "15px solid white",
                        marginTop: "0px"
                        // margin: "4px"
                        // backgroundAttachment: "fixed",
                        // backgroundImage: `radial-gradient(circle, rgba(14, 173, 192, 0.7) 0%, rgba(19, 156, 225, 0.6) 70%, rgba(28, 173, 234, 0.8) 100%), url(${this.state.preview})`
                    }} className={classes.cardOverlay}>
                        <div className={classes.headerCopy}>
                            <h1 className={classes.title}><strong>Boatzia Boat Ramps</strong></h1>
                            <p className={classes.description}>The authoritative source for boat ramp details, maps,
                                comments, and more.</p>
                            <Button className={classes.buttons} variant="outlined" color="secondary" href={"/states"}>BOAT
                                RAMPS NEAR YOU</Button>
                        </div>
                    </Grid>
                </Grid>
                <div style={{left: "0"}}>
                    <Recommended county={null} ramp={null} state={null} countyMain={null}/>
                </div>
            </>
        )
    }
}

export default withStyles(styles, {withTheme: true})(HomeHeader);