import React from "react";
// import app from "../base";
import HomeHeader from "../ui_elements/home/HomeHeader";
// import Newsletter from "../ui_elements/newsletter/Newsletter";
// import ColorBlocks from "../ui_elements/colorblocks/Colorblocks";
import { Grid } from '@material-ui/core';

const Home = () => {
    return (
        <Grid container>
            <Grid item md={12}>
                <HomeHeader />
            </Grid>
            <Grid item md={12}>
                {/* <h1>HUNTEDX</h1> */}
                {/* <Button variant="contained" color="secondary" href={"/account/"}>User Account</Button> */}
                {/* <ColorBlocks top={"10px"} bottom={"10px"} width={"1200px"} data={[{
                    byline: "INSIDER",
                    title: "Join our Insider club to see exclusive content.",
                    backgroundImage: "https://images.unsplash.com/photo-1555412654-72a95a495858?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=734&q=80",
                    backgroundColor: "",
                    text: "white",
                    button: "REGISTER",
                    buttonColor: "white",
                    width: 7,
                    href: "/article/pros-and-cons-of-home-equity-loans/"
                },
                {
                    byline: "TOP HUNTER",
                    title: "See the current top hunter and enter your picture to be featured.",
                    backgroundImage: "",
                    backgroundColor: "#8A925E",
                    text: "white",
                    button: "FEATURED TODAY",
                    buttonColor: "white",
                    width: 5,
                    href: "/article/what-are-the-different-types-of-home-loans-and-which-mortgage-is-right-for-you/"
                },
                {
                    byline: "NEXT HUNT",
                    title: "Best tips for your next hunt.",
                    backgroundImage: "",
                    backgroundColor: "#79866d",
                    text: "white",
                    button: "READ MORE",
                    buttonColor: "white",
                    width: 5,
                    href: "/article/how-does-a-reverse-mortgage-work/"
                },
                {
                    byline: "DRAW ODDS",
                    title: "What states have the best DRAW ODDS for your next big game hunt?",
                    backgroundImage: "https://www.fabricgateway.com/images/fabricgateway/ef/ef90a9edf8166c6c3eb564cf7e9eb54f.jpeg",
                    backgroundColor: "#c5c086",
                    text: "white",
                    button: "FIRST-TIME BUYERS",
                    buttonColor: "white",
                    width: 7,
                    href: "/article/12-common-mistakes-made-by-first-time-home-buyers-and-how-to-avoid-them/"
                }]} />
                <ColorBlocks top={"-10px"} bottom={"-20px"} width={"1200px"} data={[{
                    byline: "HUNTING REPORTS",
                    title: "Local hunting reports, tips, and reviews provided by hunters in your area.",
                    backgroundImage: "https://media.istockphoto.com/vectors/camouflage-pattern-dark-black-seamless-texture-vector-camo-print-vector-id1205055043?k=6&m=1205055043&s=170667a&w=0&h=so8utZS16XZMR5XudY93aXDoPLNb2LEGxucIeacqzow=",
                    backgroundColor: "",
                    text: "white",
                    button: "REGISTER",
                    buttonColor: "white",
                    width: 8,
                    href: "/article/pros-and-cons-of-home-equity-loans/"
                },
                {
                    byline: "TOP HUNTER",
                    title: "See the current top hunter and enter your picture to be featured.",
                    backgroundImage: "",
                    backgroundColor: "#8A925E",
                    text: "white",
                    button: "FEATURED TODAY",
                    buttonColor: "white",
                    width: 4,
                    href: "/article/what-are-the-different-types-of-home-loans-and-which-mortgage-is-right-for-you/"
                }]} /> */}
            </Grid>
        </Grid>
    )
}

export default Home;