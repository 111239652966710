import React from 'react';
import { Grid, AppBar, Toolbar, Typography, IconButton, SwipeableDrawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import app from "../base";

// import menus from "../menus.json";
// import {Menu, LocationCity, SettingsOutlined, ClearAllOutlined, ColorLensSharp, PermMediaSharp, SupervisorAccountSharp, HistorySharp} from '@material-ui/icons/';
// import logo from '../assets/logo.png';
// import { v4 as uuidv4 } from 'uuid';
import { withStyles } from "@material-ui/core/styles";
// import postlist from "../posts.json";
// import NavbarArticle from "./ui_elements/navbar/NavbarArticle.js";
// import useMediaQuery from '@material-ui/core/useMediaQuery';

// import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = theme => ({
    grid: {
        // margin: "20px"
    },
    button: {
        width: "250px",
        '&:focus': {
            backgroundColor: "#002884",
            color: "white"
        }
    },
    appbar: {
        backgroundColor: "#254DAB",
        boxShadow: "0px 4px 9px 0 rgba(0,0,0,.4), 0 1px 5px -1px rgba(0,0,0,.6)",
        color: "#213159"
        // display: "flex",
        // flexDirection: "row",
        // justifyContent: "center",
        // '@media screen and (max-width: 900px)': {
        // marginBottom: "100px",
        // margin: "0 auto"
        // }
    },
    navbar: {
        width: '100%',
        // position: "absolute",
        // marginBottom: "-5px",
        // marginBottom: "80px"
    },
    toolbar: {
        margin: "0 auto",
        '@media screen and (min-width: 900px)': {
            maxWidth: "1600px",
            // margin: "0 auto"
        }
    },
    menuButton: {
        color: "white",
        '&:focus': {
            backgroundColor: "transparent",
            color: "white"
        },
        '@media screen and (min-width: 900px)': {
            display: "none",
        }
    },
    list: {
        width: '100%',
        maxWidth: "320px",
        minWidth: "240px",
        backgroundColor: theme.palette.background.paper,
        paddingTop: "25% !important"
    },
    listItem: {
        color: "#213159",
        paddingTop: "0px !important",
        paddingBottom: "0px !important"
    },
    nestedListItem: {
        color: "white",
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        marginLeft: "10px"
    },
    // listItemIcon: {
    //   marginTop: "-5px",
    //   size: "13px"
    // },
    title: {
        textAlign: "center",
        flexDirection: "column",
        flex: "auto",
        fontFamily: "'CircularStd-Bold',Arial,sans-serif",
        letterSpacing: "1px",
        textDecoration: "none",
        color: "white",
        fontWeight: 600,
        fontSize: "1.6rem",
        marginLeft: "-20px",
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    name: {
        fontSize: "11px",
        maxWidth: "100px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginLeft: "-45px"
    },
    icon: {
        marginBottom: "-7px",
        marginRight: "4px"
    },
    profileIcon: {
        marginBottom: "-7px",
        // marginRight: "4px"
    },
    logo: {
        marginLeft: "-45px",
        margin: "0 auto",
        '@media screen and (min-width: 900px)': {
            justifyContent: "left",
            marginLeft: "0px"
        }
    },

    desktopLinks: {
        textAlign: "right",
        '@media screen and (max-width: 900px)': {
            display: "none",
        }
    },
    desktopItem: {
        color: "white",
        padding: "0px 20px",
        paddingTop: "80px",
        paddingBottom: "15px",
        textDecoration: "none",
        fontSize: "14px",
        '&:hover': {
            backgroundColor: "#1555E9",
            borderBottom: "7px solid white",
            color: "white",
        },
    }
});

class NavTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        }
    }
    componentDidMount = () => {
        this.setState({
            isLoaded: true,
            left: false
        });
    }


    // DESKTOP MENU ITEM
    menuItem = (prop) => {
        this.setState({ [prop + "_open"]: true, open: true })
        // USE TIMEOUT INCASE THE USER DOESN'T HOVER OVER THE SUBNAV ITEMS
        setTimeout(() => {
            if (this.state[prop + "_open"] === true && this.state[prop + "_hover"] === false) {
                this.setState({
                    [prop + "_open"]: this.state[prop + "_open"],
                    open: true
                })
            }
        }, 600)
    }
    // DESKTOP MENU ITEM NAVIGATED AWAY
    menuItemLeft = (prop) => {
        setTimeout(() => {
            if (this.state[prop + "_hover"] === false) {
                this.setState({ [prop + "_open"]: false, open: false })
            }
        }, 600)
    }
    // MOBILE MENU ITEM
    mobileMenuItem = (prop) => {
        this.setState({ [prop]: !this.state.prop })
    }

    drawerClicked = (prop) => {
        this.setState({ left: prop })
    }

    render() {
        const { classes } = this.props;
        const list = () => (
            <List className={classes.list}>
                <ListItem button component="a" href="/">
                    <ListItemText className={classes.listItem} primary="Home" />
                </ListItem>
                <ListItem button component="a" href="/states/">
                    <ListItemText className={classes.listItem} primary="Ramps By States" />
                </ListItem>
            </List>
        );

        return (
            <div className={classes.navbar}>
                <AppBar position="fixed" className={classes.appbar}>
                    <Toolbar>
                        <SwipeableDrawer
                            open={(this.state.left)? 'left' : false}
                            onClose={() => this.drawerClicked(false)}
                            onOpen={() => this.drawerClicked(true)}
                        >
                            {list()}
                        </SwipeableDrawer>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => this.drawerClicked(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Grid container direction="row" alignItems="center" className={classes.toolbar}>
                            <Grid item md={3} s={12} xs={12}>
                                <Typography variant="body1" button component="a" href="/" className={classes.title}>boatzia.</Typography> 
                                {/* BOATZIA parklia*/}
                            </Grid>
                            <Grid item md={8} className={classes.desktopLinks}>
                                    <>
                                        <Typography variant="body1" button component="a" href="/states/" className={classes.desktopItem}>ramps by states</Typography>
                                        {/* <Typography variant="body1" button component="a" href="/signup" className={classes.desktopItem}>Sign Up</Typography> */}
                                    </>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(NavTabs);