import React from "react";
import {BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { AuthProvider } from "./Auth";
// import PrivateRoute from "./PrivateRoute";
import Home from "./screens/Home";
import States from "./screens/States";
import County from "./screens/County";
import Counties from "./screens/Counties";
import Ramp from "./screens/Ramp";
import Page from "./screens/Page";
// import Details from "./screens/details/Details";

// import Parks from "./screens/Parks";
// import Park from "./screens/parks/Park";
// import ParkHistory from "./screens/parks/ParkPage";
// import ParkQuestion from "./screens/parks/pages/Faqs";
// import ParkGallery from "./screens/parks/pages/Gallery";
// import ParkHours from "./screens/parks/pages/Hours";
// import ParkRating from "./screens/parks/pages/Rating";
// import ParkReviews from "./screens/parks/pages/Reviews";

// import Login from "./SignIn";
// import AuthForms from "./AuthForms";
// import Post from "./screens/Post";
// import UserDashboard from "./screens/UserDashboard";
// import SignUp from "./SignUp";
// import Account from "./screens/Account";

const RTR = () => {
    return (
            <Router>
                <Route exact path="/" component={Home} />
                {/* <Route path='/:state/park/:park/history/' component={ParkHistory} /> */}
                {/* <Route path='/:state/park/:park/gallery' component={ParkGallery} /> */}
                {/* <Route path='/:state/park/:park/hours' component={ParkHours} /> */}
                {/* <Route path='/:state/park/:park/rating' component={ParkRating} /> */}
                {/* <Route path='/:state/park/:park/reviews' component={ParkReviews} /> */}
                {/* <Route path='/:state/park/:park/:question' component={ParkQuestion} /> */}
                <Switch>
                <Route exact path="/states/" component={States} />
                <Route exact path="/:page/" component={Page} />
                {/* <Route exact path='/:state/park/:park/' component={Park} /> */}
                {/* <Route exact path='/:state/park/:park/:parkpage' component={ParkHistory} /> */}
                {/*<Route path="/:state/:county/:ramp/:question/" component={Details} />*/}
                <Route path="/:state/:county/:ramp/" component={Ramp} />
                {/* <Route exact path="/:state/parks/" component={Parks} />  */}
                <Route exact={true} path='/:state/counties/' component={Counties} />
                <Route exact={true} path='/:state/ramps/' component={Counties} />
                <Route path="/:state/:county" component={County} />
                </Switch>

                {/* <Route exact path="/forgot-password" component={() => <AuthForms form={"forgot password"} />}/> */}
                {/* <PrivateRoute exact path="/account/" component={() => <Account />} /> */}
                {/* <Route path="*" component={Home} status={404} /> */}
            </Router>
    )
}

export default RTR;