import React from "react";
import {Redirect} from "react-router";
import {BrowserRouter as Router} from "react-router-dom";


function Page(props) {
    let page = (window.location.pathname.split('/')[1].split('/')[0] !== undefined) ? window.location.pathname.split('/')[1].split('/')[0] : null;
    let title = page.toUpperCase().replace(/-/g, " ");
    switch (page) {
        case "cookies":
            return (<>
                <h1 style={{margin: "150px 50px 80px 50px"}}>{title}</h1>
                <CookiePolicy/>
            </>);
        case "privacy-policy":
            return (<>
                <h1 style={{margin: "150px 50px 80px 50px"}}>{title}</h1>
                <PrivacyPolicy/>
            </>);
        case "terms-of-use":
            return (<>
                <h1 style={{margin: "150px 50px 80px 50px"}}>{title}</h1>
                <TermsNConditions/>
            </>)
        case "contact":
            return (<>
                <h1 style={{margin: "150px 50px 80px 50px"}}>{title}</h1>
                <p>Feel free to contact Boatzia anytime using the contact form provided below.</p>
            </>)
        case "about":
            return (<>
                <h1 style={{margin: "150px 50px 80px 50px"}}>{title}</h1>
                <About/>
            </>)
        case "do-not-sell-my-info":
            return (<>
                <h1 style={{margin: "150px 50px 80px 50px"}}>Do Not Sell My Personal Information</h1>
                <DoNotSell/>
            </>)
        default:
            return (
                <Router
                    forceRefresh={true}
                >
                    <Redirect
                        status={301}
                        to={{
                            pathname: `/`,
                            search: "",
                        }}
                    >
                    </Redirect>
                </Router>
            );
    }
}

function DoNotSell() {
    return (
        <div style={{maxWidth: "800px", margin: "0 auto", textAlign: "left", marginBottom: "100px"}}>
            <p>This notice and the options below apply only to California residents.</p>

            <p>If you are a California resident, the California Consumer Privacy Act (“CCPA”) provides you with the
                right to opt out of the “sale” of your Personal Information.</p>

            <p>Please review our <a href="/privacy-policy/">Privacy Policy</a> for a more detailed description of how we collect, use, and share the
                Personal Information of California residents in operating our business; your privacy rights as a
                California resident; and how to exercise your rights as a California resident. For the purposes of this
                notice, “Personal Information” has the meaning given in the CCPA, but does not include information
                exempted from the scope of the CCPA.</p>

            <p>Boatzia does not directly sell your Personal Information in the conventional sense (i.e.,
                for money). Like many companies, however, we use services that help deliver interest-based ads to you
                and may transfer Personal Information to business partners for their use. Making Personal Information
                (such as online identifiers or browsing activity) available to these companies may be considered a
                “sale” under the CCPA.</p>

            <p>To request that Boatzia may not “sell” your Personal Information, please contact us via our contact form located <a href="/contact/">here</a>.
                Please note, some transfers of your Personal Information may not be considered “sales” and certain
                exemptions may apply under the CCPA.</p>

            <p>If you have any other questions or requests, please contact us via the contact form <a href="/contact/">here</a></p>



            <p>In addition to California residents’ ability to request that Boatzia not to “sell” your Personal
                Information, there are a number of tools and methods that any user — regardless of location —may use to
                opt out of having your online activity and device data collected by third parties, which we have
                summarized below. We hope you find this information to be a helpful reference. Please note that using
                these tools to opt out of tracking and targeting does not mean that you will not receive advertising
                while using our Services or on other websites, nor will it prevent the receipt of interest-based
                advertising from third parties that do not participate in these programs. It will exclude you, however,
                from interest-based advertising conducted through participating networks and platforms, as provided by
                their policies and choice mechanisms.</p>

            <p>Blocking Cookies in Your Browser. Most web browsers automatically accept cookies, but also usually allow
                you to modify your settings to disable or reject cookies, including cookies for interest-based
                advertising. If you delete your cookies or if you set your web browser to decline cookies, however, some
                features of the Services may not work or may not work as designed. You can usually find these settings
                in the Options or Preferences menu of your browser; links to instructions provided by several of the
                most common browsers are below. You can also learn more at <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.</p>

            <p>Cookie settings in Google Chrome</p>
            <p>Cookie settings in Firefox</p>
            <p>Cookie settings in Internet Explorer</p>
            <p>Cookie settings in Safari web and iOS.</p>
            <p>Blocking advertising ID use in your mobile settings. Your mobile device settings may provide
                functionality to limit use of the advertising ID associated with your mobile device for interest-based
                advertising purposes.</p>

            <p>Advertising Industry Opt Out Tools. Many of the third party advertisers that use tracking or targeting
                tools in connection with our Services either directly, or are members of programs that, offer you
                additional choices regarding the collection and use of your information. You can learn more about the
                options available to limit these participating third parties’ collection and use of your information by
                visiting their websites:</p>

            <p>Opt Out of Interest Based Advertising (National Advertising Initiative)</p>
            <p>Your Ad Choices (Digital Advertising Alliance)</p>
            <p>Your Online Choices (European Interactive Digital Advertising Alliance)</p>
            <p>Users of our mobile apps may opt out of receiving targeted advertising in mobile apps through
                participating members of the Digital Advertising Alliance by installing the AppChoices mobile app,
                available here, and selecting the user’s choices.</p>
            <p>Platform Opt Out Tools. Google and Facebook offer opt-out features that let you opt-out of use of your
                information for interest-based advertising.</p>

            <p>Facebook: About Facebook Ads and Facebook Privacy Policy</p>
            <p>Google: The Services may deliver advertising using the vendor DoubleClick, a Google company. You can read
                about how DoubleClick uses cookies, use Google’s controls for blocking certain ads, or use Google’s Ad
                Settings Page to control how DoubleClick personalizes your ad experience. The Services also may use
                Google Analytics to track your usage, including the stitching of authenticated and unauthenticated
                sessions. If you wish to opt-out of Google Analytics’ tracking, use this browser add-on provided by Google.</p>
            <p>Mobile App Tracking Opt Out Tools. You can learn about your options to opt-out of mobile app tracking by
                certain advertising networks through your device settings. For more information about how to change
                these settings for Apple, Android or Windows devices, see:</p>

            <p>Apple: http://support.apple.com/kb/HT4228</p>
            <p>Android: http://www.google.com/policies/technologies/ads/</p>
            <p>Windows: http://choice.microsoft.com/en-US/opt-out</p>
            <p>Social Media Services Settings. If you choose to connect to the Services via a third-party platform or
                social media network, you may have the ability to limit the information that we may obtain from the
                third-party at the time you login to the Services using the third-party’s authentication service or
                otherwise connect your account. Subsequently, you may be able to control your settings through the
                third-party’s platform or service. For example, you may access and change your settings through the
                Facebook settings page for Apps and Websites. If you withdraw our ability to access certain information
                from a third-party platform or social media network, that choice will not apply to information that we
                have already received from that third-party.</p>

            <p>Using Privacy Plug-ins or Browsers. You can block our websites from setting cookies used for
                interest-based ads by using a browser with privacy features, like Brave, or installing browser plugins
                like Privacy Badger, Ghostery or uBlock Origin, and configuring them to block third party
                cookies/trackers.</p>
        </div>
    )
}

function About() {
    return (
        <div style={{maxWidth: "800px", margin: "0 auto", textAlign: "left", marginBottom: "100px"}}>
            <p>Boatzia was founded in 2021 and is currently the leading provider of boat ramp and marina information in
                Florida with future plans to expand to other states. We provide location information with interactive
                maps of the launch ramps in Florida. Additionally, we provide information that many weekend boaters
                would love to know such as the number of bathrooms, how many launch slips there are, is it safe to swim,
                are the facilities handicapped accessible and so much more! We strive to be the go to place for boaters
                in Florida and hope to help answer all their questions before a day out on the water.</p>
            <p>This site will continue to improve over time as we plan to continue adding new data to our data set which
                should allow us to provide our users with even more useful information. We also plan to introduce
                several new features on the platform over the coming year which will allow us to provide an even better
                user experience.</p>
        </div>
    )
}

function CookiePolicy() {
    // https://www.cookiepolicygenerator.com/download.php?lang=en&token=ReDyN6yflnFZC1m4wxb1F9GYbhJMhYF0#
    return (
        <div style={{maxWidth: "800px", margin: "0 auto", textAlign: "left", marginBottom: "100px"}}>
            <h1>Cookie Policy for Boatzia</h1>
            <p>This is the Cookie Policy for Boatzia, accessible from https://boatzia.com</p>
            <p><strong>What Are Cookies</strong></p>
            <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files
                that are downloaded to your computer, to improve your experience. This page describes what information
                they gather, how we use it and why we sometimes need to store these cookies. We will also share how you
                can prevent these cookies from being stored however this may downgrade or 'break' certain elements of
                the sites functionality.</p>
            <p><strong>How We Use Cookies</strong></p>
            <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry
                standard options for disabling cookies without completely disabling the functionality and features they
                add to this site. It is recommended that you leave on all cookies if you are not sure whether you need
                them or not in case they are used to provide a service that you use.</p>
            <p><strong>Disabling Cookies</strong></p>
            <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help
                for how to do this). Be aware that disabling cookies will affect the functionality of this and many
                other websites that you visit. Disabling cookies will usually result in also disabling certain
                functionality and features of the this site. Therefore it is recommended that you do not disable
                cookies. This Cookies Policy was created with the help of the <a
                    href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">Cookies Policy Generator</a>.
            </p>
            <p><strong>The Cookies We Set</strong></p>
            <ul>
                <li>
                    <p>Email newsletters related cookies</p>
                    <p>This site offers newsletter or email subscription services and cookies may be used to remember if
                        you are already registered and whether to show certain notifications which might only be valid
                        to subscribed/unsubscribed users.</p>
                </li>
                <li>
                    <p>Forms related cookies</p>
                    <p>When you submit data to through a form such as those found on contact pages or comment forms
                        cookies may be set to remember your user details for future correspondence.</p>
                </li>
            </ul>
            <p><strong>Third Party Cookies</strong></p>
            <p>In some special cases we also use cookies provided by trusted third parties. The following section
                details which third party cookies you might encounter through this site.</p>
            <ul>
                <li>
                    <p>This site uses Google Analytics which is one of the most widespread and trusted analytics
                        solution on the web for helping us to understand how you use the site and ways that we can
                        improve your experience. These cookies may track things such as how long you spend on the site
                        and the pages that you visit so we can continue to produce engaging content.</p>
                    <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
                </li>
                <li>
                    <p>The Google AdSense service we use to serve advertising uses a DoubleClick cookie to serve more
                        relevant ads across the web and limit the number of times that a given ad is shown to you.</p>
                    <p>For more information on Google AdSense see the official Google AdSense privacy FAQ.</p>
                </li>
            </ul>
            <p><strong>More Information</strong></p>
            <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that
                you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does
                interact with one of the features you use on our site.</p>
            <p>For more general information on cookies, please read <a
                href="https://www.cookiepolicygenerator.com/sample-cookies-policy/">the Cookies Policy article</a>.</p>
            <p>However if you are still looking for more information then you can contact us through one of our
                preferred contact methods:</p>
            <ul>
                <li>By visiting this link: https://boatzia.com/contact</li>
            </ul>
        </div>)
}
//
// function Disclaimer() {
//     // https://www.privacypolicyonline.com/download.php?lang=en&token=2xgdiMnMfgJc5SVa5ZSKwMoMWo7di50q
//     return (
//         <div style={{maxWidth: "800px", margin: "0 auto", textAlign: "left", marginBottom: "100px"}}>
//             <h1>Disclaimer for Boatzia</h1>
//
//             <p>If you require any more information or have any questions about our site's disclaimer, please feel free
//                 to contact us by email at waltirdev@gmail.com</p>
//
//             <h2>Disclaimers for Boatzia</h2>
//
//             <p>All the information on this website - https://boatzia.com - is published in good faith and for general
//                 information purpose only. Boatzia does not make any warranties about the completeness, reliability and
//                 accuracy of this information. Any action you take upon the information you find on this website
//                 (Boatzia), is strictly at your own risk. Boatzia will not be liable for any losses and/or damages in
//                 connection with the use of our website. Our Disclaimer was generated with the help of the <a
//                     href="https://www.privacypolicyonline.com/disclaimer-generator/">Disclaimer Generator</a>.</p>
//
//             <p>From our website, you can visit other websites by following hyperlinks to such external sites. While we
//                 strive to provide only quality links to useful and ethical websites, we have no control over the content
//                 and nature of these sites. These links to other websites do not imply a recommendation for all the
//                 content found on these sites. Site owners and content may change without notice and may occur before we
//                 have the opportunity to remove a link which may have gone 'bad'.</p>
//
//             <p>Please be also aware that when you leave our website, other sites may have different privacy policies and
//                 terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well
//                 as their "Terms of Service" before engaging in any business or uploading any information.</p>
//
//             <h2>Consent</h2>
//
//             <p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>
//
//             <h2>Update</h2>
//
//             <p>Should we update, amend or make any changes to this document, those changes will be prominently posted
//                 here.</p>
//         </div>
//     )
// }


function TermsNConditions() {
    //https://www.privacypolicyonline.com/download.php?lang=en&token=2xgdiMnMfgJc5SVa5ZSKwMoMWo7di50q
    return (
        <div style={{maxWidth: "800px", margin: "0 auto", textAlign: "left", marginBottom: "100px"}}>
            <h2><strong>Terms and Conditions</strong></h2>

            <p>Welcome to Boatzia!</p>

            <p>These terms and conditions outline the rules and regulations for the use of Boatzia's Website, located at
                https://boatzia.com.</p>

            <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Boatzia
                if you do not agree to take all of the terms and conditions stated on this page.</p>

            <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
                and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and
                compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us",
                refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms
                refer to the offer, acceptance and consideration of payment necessary to undertake the process of our
                assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s
                needs in respect of provision of the Company’s stated services, in accordance with and subject to,
                prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
                Our Terms and Conditions were created with the help of the <a
                    href="https://www.privacypolicyonline.com/terms-conditions-generator/">Terms & Conditions
                    Generator</a>.</p>

            <h3><strong>Cookies</strong></h3>

            <p>We employ the use of cookies. By accessing Boatzia, you agreed to use cookies in agreement with the
                Boatzia's Privacy Policy.</p>

            <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are
                used by our website to enable the functionality of certain areas to make it easier for people visiting
                our website. Some of our affiliate/advertising partners may also use cookies.</p>

            <h3><strong>License</strong></h3>

            <p>Unless otherwise stated, Boatzia and/or its licensors own the intellectual property rights for all
                material on Boatzia. All intellectual property rights are reserved. You may access this from Boatzia for
                your own personal use subjected to restrictions set in these terms and conditions.</p>

            <p>You must not:</p>
            <ul>
                <li>Republish material from Boatzia</li>
                <li>Sell, rent or sub-license material from Boatzia</li>
                <li>Reproduce, duplicate or copy material from Boatzia</li>
                <li>Redistribute content from Boatzia</li>
            </ul>

            <p>This Agreement shall begin on the date hereof.</p>

            <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in
                certain areas of the website. Boatzia does not filter, edit, publish or review Comments prior to their
                presence on the website. Comments do not reflect the views and opinions of Boatzia,its agents and/or
                affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To
                the extent permitted by applicable laws, Boatzia shall not be liable for the Comments or for any
                liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or
                appearance of the Comments on this website.</p>

            <p>Boatzia reserves the right to monitor all Comments and to remove any Comments which can be considered
                inappropriate, offensive or causes breach of these Terms and Conditions.</p>

            <p>You warrant and represent that:</p>

            <ul>
                <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to
                    do so;
                </li>
                <li>The Comments do not invade any intellectual property right, including without limitation copyright,
                    patent or trademark of any third party;
                </li>
                <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful
                    material which is an invasion of privacy
                </li>
                <li>The Comments will not be used to solicit or promote business or custom or present commercial
                    activities or unlawful activity.
                </li>
            </ul>

            <p>You hereby grant Boatzia a non-exclusive license to use, reproduce, edit and authorize others to use,
                reproduce and edit any of your Comments in any and all forms, formats or media.</p>

            <h3><strong>Hyperlinking to our Content</strong></h3>

            <p>The following organizations may link to our Website without prior written approval:</p>

            <ul>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the
                    Websites of other listed businesses; and
                </li>
                <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping
                    malls, and charity fundraising groups which may not hyperlink to our Web site.
                </li>
            </ul>

            <p>These organizations may link to our home page, to publications or to other Website information so long as
                the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or
                approval of the linking party and its products and/or services; and (c) fits within the context of the
                linking party’s site.</p>

            <p>We may consider and approve other link requests from the following types of organizations:</p>

            <ul>
                <li>commonly-known consumer and/or business information sources;</li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                <li>educational institutions and trade associations.</li>
            </ul>

            <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us
                look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any
                negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the
                absence of Boatzia; and (d) the link is in the context of general resource information.</p>

            <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b)
                does not falsely imply sponsorship, endorsement or approval of the linking party and its products or
                services; and (c) fits within the context of the linking party’s site.</p>

            <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our
                website, you must inform us by sending an e-mail to Boatzia. Please include your name, your organization
                name, contact information as well as the URL of your site, a list of any URLs from which you intend to
                link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks
                for a response.</p>

            <p>Approved organizations may hyperlink to our Website as follows:</p>

            <ul>
                <li>By use of our corporate name; or</li>
                <li>By use of the uniform resource locator being linked to; or</li>
                <li>By use of any other description of our Website being linked to that makes sense within the context
                    and format of content on the linking party’s site.
                </li>
            </ul>

            <p>No use of Boatzia's logo or other artwork will be allowed for linking absent a trademark license
                agreement.</p>

            <h3><strong>iFrames</strong></h3>

            <p>Without prior approval and written permission, you may not create frames around our Webpages that alter
                in any way the visual presentation or appearance of our Website.</p>

            <h3><strong>Content Liability</strong></h3>

            <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and
                defend us against all claims that is rising on your Website. No link(s) should appear on any Website
                that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or
                advocates the infringement or other violation of, any third party rights.</p>

            <h3><strong>Reservation of Rights</strong></h3>

            <p>We reserve the right to request that you remove all links or any particular link to our Website. You
                approve to immediately remove all links to our Website upon request. We also reserve the right to amen
                these terms and conditions and it’s linking policy at any time. By continuously linking to our Website,
                you agree to be bound to and follow these linking terms and conditions.</p>

            <h3><strong>Removal of links from our website</strong></h3>

            <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform
                us any moment. We will consider requests to remove links but we are not obligated to or so or to respond
                to you directly.</p>

            <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or
                accuracy; nor do we promise to ensure that the website remains available or that the material on the
                website is kept up to date.</p>

            <h3><strong>Disclaimer</strong></h3>

            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and
                conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

            <ul>
                <li>limit or exclude our or your liability for death or personal injury;</li>
                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
            </ul>

            <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a)
                are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer,
                including liabilities arising in contract, in tort and for breach of statutory duty.</p>

            <p>As long as the website and the information and services on the website are provided free of charge, we
                will not be liable for any loss or damage of any nature.</p>
        </div>
    )
}

function PrivacyPolicy() {
    // https://www.privacypolicyonline.com/download.php?lang=en&token=2xgdiMnMfgJc5SVa5ZSKwMoMWo7di50q
    return (
        <div style={{maxWidth: "800px", margin: "0 auto", textAlign: "left", marginBottom: "100px"}}>
            <h1>Privacy Policy for Boatzia</h1>

            <p>At Boatzia, accessible from https://boatzia.com, one of our main priorities is the privacy of our
                visitors. This Privacy Policy document contains types of information that is collected and recorded by
                Boatzia and how we use it.</p>

            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to
                contact us.</p>

            <h2>Log Files</h2>

            <p>Boatzia follows a standard procedure of using log files. These files log visitors when they visit
                websites. All hosting companies do this and a part of hosting services' analytics. The information
                collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider
                (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not
                linked to any information that is personally identifiable. The purpose of the information is for
                analyzing trends, administering the site, tracking users' movement on the website, and gathering
                demographic information. Our Privacy Policy was created with the help of the <a
                    href="https://www.privacypolicyonline.com/privacy-policy-generator/">Privacy Policy Generator</a>.
            </p>

            <h2>Cookies and Web Beacons</h2>

            <p>Like any other website, Boatzia uses 'cookies'. These cookies are used to store information including
                visitors' preferences, and the pages on the website that the visitor accessed or visited. The
                information is used to optimize the users' experience by customizing our web page content based on
                visitors' browser type and/or other information.</p>

            <p>For more general information on cookies, please read <a
                href="https://www.privacypolicyonline.com/what-are-cookies/">the "Cookies" article from the Privacy
                Policy Generator</a>.</p>

            <h2>Google DoubleClick DART Cookie</h2>

            <p>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve
                ads to our site visitors based upon their visit to www.website.com and other sites on the internet.
                However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content
                network Privacy Policy at the following URL – <a
                    href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
            </p>


            <h2>Privacy Policies</h2>

            <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Boatzia.</p>

            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are
                used in their respective advertisements and links that appear on Boatzia, which are sent directly to
                users' browser. They automatically receive your IP address when this occurs. These technologies are used
                to measure the effectiveness of their advertising campaigns and/or to personalize the advertising
                content that you see on websites that you visit.</p>

            <p>Note that Boatzia has no access to or control over these cookies that are used by third-party
                advertisers.</p>

            <h2>Third Party Privacy Policies</h2>

            <p>Boatzia's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to
                consult the respective Privacy Policies of these third-party ad servers for more detailed information.
                It may include their practices and instructions about how to opt-out of certain options. </p>

            <p>You can choose to disable cookies through your individual browser options. To know more detailed
                information about cookie management with specific web browsers, it can be found at the browsers'
                respective websites. What Are Cookies?</p>

            <h2>Children's Information</h2>

            <p>Another part of our priority is adding protection for children while using the internet. We encourage
                parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

            <p>Boatzia does not knowingly collect any Personal Identifiable Information from children under the age of
                13. If you think that your child provided this kind of information on our website, we strongly encourage
                you to contact us immediately and we will do our best efforts to promptly remove such information from
                our records.</p>

            <h2>Online Privacy Policy Only</h2>

            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with
                regards to the information that they shared and/or collect in Boatzia. This policy is not applicable to
                any information collected offline or via channels other than this website.</p>

            <h2>Consent</h2>

            <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
        </div>
    )
}

export default Page;

