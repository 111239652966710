import React from 'react';
import './styles/App.css';
import ReactGA from 'react-ga';
import Router from './components/Router';
import Navbar from './components/ui_elements/Navbar';
import Footer from './components/ui_elements/Footer';
import CookieConsent from "react-cookie-consent";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      matches: window.matchMedia("(min-width: 768px)").matches
    };
  }

  componentDidMount() {
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }

  render() {
    // function App() {
    ReactGA.initialize('UA-181751179-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="App">

        {/* MEDIA QUERY IN USE TO MAKE MOBILE BUTTON FULL WIDTH */}
        {this.state.matches && (
          <CookieConsent
            location="bottom"
            buttonText="accept"
            cookieName="cookieConsentProvided"
            style={{ background: "white", padding: "60px 0px", boxShadow: "0 0 9px 4px rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.2)" }}
            buttonStyle={{ backgroundColor: "black", color: "white", fontSize: "15px", marginRight: "50px", padding: "10px 30px", fontWeight: "100", fontFamily: "'CircularStd-Bold',Arial,sans-serif" }}
            expires={50}
          >
            <span style={{ fontWeight: "100", fontSize: "15px", color: "black" }}>This website uses cookies to enhance the user experience. You can read more about our cookies and how we use them <a style={{ color: "blue", fontWeight: "600", textDecoration: "none" }} href="/cookies">here</a>.</span>
          </CookieConsent>
        )}
        {!this.state.matches && (
          <CookieConsent
            location="bottom"
            buttonText="accept"
            cookieName="cookieConsentProvided"
            style={{ background: "white", padding: "40px 0px 60px 0px", boxShadow: "0 0 9px 4px rgba(21,21,21,.1), 0 2px 8px -1px rgba(21,21,21,.2)" }}
            buttonStyle={{ width: "91vw", backgroundColor: "black", color: "white", fontSize: "15px", marginRight: "50px", padding: "10px 30px", fontWeight: "100", fontFamily: "'CircularStd-Bold',Arial,sans-serif" }}
            expires={50}
          >
            <div style={{ padding: "0px 20px 0px 20px" }}>
              <span style={{ fontWeight: "100", fontSize: "13px", color: "black" }}>This website uses cookies to enhance the user experience. You can read more about our cookies and how we use them <a style={{ color: "blue", fontWeight: "600", textDecoration: "none" }} href="/cookies">here</a>.</span>
            </div>
          </CookieConsent>
        )}
        <Navbar />
        <Router />
        <Footer />
        {/* <AdSense.Google
          client='ca-pub-6183357078733821'
          // slot='7806394673'
          slot='6183357078733821'
        /> */}

      </div>
    );
  }
}

export default App;