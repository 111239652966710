import React from "react";
// import app from "../base";
// import HomeHeader from "../ui_elements/home/HomeHeader";
// import Newsletter from "../ui_elements/newsletter/Newsletter";
// import ColorBlocks from "../ui_elements/colorblocks/Colorblocks";
// import { Grid, Link } from '@material-ui/core';
// import Meta from "../meta_elements/Meta";
// import JsonLD from "../meta_elements/JsonLD";
// import data from '../../data/data.json';
import data from '../../data/data.json';
import { v4 as uuidv4 } from 'uuid';

import loadable from "@loadable/component";
const Meta = loadable(() => import('../meta_elements/Meta'));
const JsonLD = loadable(() => import('../meta_elements/JsonLD'));
const Grid = loadable(() => import('@material-ui/core/Grid'));
const Link = loadable(() => import('@material-ui/core/Link'));

const Home = () => {
    return (
        <Grid container>
            <Meta
                screen={"ramp"}
                title={`Boat Ramps For All States In The United States`}
                description={`Boat Ramps For All States In The United States`}
                keywords={`boat ramps, boat ramp, launch ramp`}
                image={`https://images.unsplash.com/photo-1564936591310-7eecf6d1e99d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1075&q=80`}
                url={`https://boatzia.com/states/`}
            />
            <JsonLD
                data={{
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": `Boat Ramps For All States In The United States`,
                    "description": `Boat Ramps For All States In The United States`,
                    "publisher": {
                        "@type": "ItemPage",
                        "name": `Boat Ramps For All States In The United States`
                    }
                }}
            />
            {/* <Grid item md={12}>
                <HomeHeader />
            </Grid> */}
            <Grid container style={{ margin: "150px 20px" }}>
                <h1 style={{margin: "0 auto", padding: "0 0 50px 0"}}>Boat Ramps By State</h1>
                <Grid item lg={12}>
                    <p style={{maxWidth: "500px", textAlign: "left", margin: "0 auto", padding: "0px 0px 60px 0px"}}>Below you'll find each state that Boatzia currently has boat ramp data on. We feel confident that we're presenting accurate and up to date information/data on over 95%+ of the boat ramps in these states. We're an authoritative source on boat ramps in each of these states and feel confident you'll find the information your looking for before your next boating adventure.</p>
                </Grid>
                {Object.keys(data).map(function (keyName, keyIndex) {
                    return (<Grid  key={uuidv4()} item md={12}>
                        <Link key={uuidv4()} style={{textTransform: "capitalize"}} href={`/${keyName}/counties/`}>{keyName} Boat Ramps</Link>
                    </Grid>)
                })}
            </Grid>
        </Grid>
    )
}

export default Home;